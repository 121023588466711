import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import usePatientInfo from 'containers/usePatientInfo' // Import your usePatientInfo hook
import useSymptoms from 'containers/useSymptoms' // Import your useSymptoms hook

type TriageResponse = {
  triage: {
    triage_level:
      | 'emergency_ambulance'
      | 'emergency'
      | 'consultation_24'
      | 'consultation'
      | 'self_care'
    serious: {
      id: string
      name: string
      common_name: string
      is_emergency: boolean
    }[]
  }
}

export default () => {
  const { age, gender } = usePatientInfo()
  const { symptoms } = useSymptoms()

  const evidence = symptoms.map((symptom) => {
    const ev = {
      id: symptom.id,
      choice_id: symptom.choiceId,
      source: symptom.source,
    }

    return ev
  })

  const { loading, error, data } = useQuery<TriageResponse>(
    gql`
      query triage($input: TriageInput!) {
        triage(input: $input)
          @rest(type: "Triage", path: "triage/", method: "POST") {
          triage_level
          serious @type(name: "TriageSymptom") {
            id
            name
            common_name
            is_emergency
          }
        }
      }
    `,
    {
      variables: {
        input: {
          age: { value: age },
          sex: gender,
          evidence,
          extras: { enable_triage_5: true },
        },
      },
      ssr: false,
    }
  )

  return {
    loading,
    data: !loading && data && data.triage,
    error,
  }
}
